import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`10 avsnitt av Trevlig Mjukvara! (säsong 2) WOHOOO`}</li>
      <li parentName="ul">{`Programmerare hindrar stämningar: `}<a parentName="li" {...{
          "href": "https://www.musictech.net/news/programmers-generate-every-possible-melody-in-midi-to-prevent-lawsuits/"
        }}>{`https://www.musictech.net/news/programmers-generate-every-possible-melody-in-midi-to-prevent-lawsuits/`}</a></li>
      <li parentName="ul">{`Let's Encrypt har levererat MILJARDER: `}<a parentName="li" {...{
          "href": "https://letsencrypt.org/2020/02/27/one-billion-certs.html"
        }}>{`https://letsencrypt.org/2020/02/27/one-billion-certs.html`}</a></li>
      <li parentName="ul">{`DNS över HTTPS: `}<a parentName="li" {...{
          "href": "https://blog.mozilla.org/netpolicy/2020/02/25/the-facts-mozillas-dns-over-https-doh/"
        }}>{`https://blog.mozilla.org/netpolicy/2020/02/25/the-facts-mozillas-dns-over-https-doh/`}</a></li>
      <li parentName="ul">{`Google Earth använder nu Wasm: `}<a parentName="li" {...{
          "href": "https://medium.com/google-earth/google-earth-comes-to-more-browsers-thanks-to-webassembly-1877d95810d6"
        }}>{`https://medium.com/google-earth/google-earth-comes-to-more-browsers-thanks-to-webassembly-1877d95810d6`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`IKEA trådfri, för folket? Inte riktigt än...`}</li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Några favorit-plugins (tack Josefine):`}<ul parentName="li">
          <li parentName="ul">{`uBlock origin (Alex + Seb)`}</li>
          <li parentName="ul">{`Bitwarden (Alex + Seb)`}</li>
          <li parentName="ul">{`Containers (Alex)`}<ul parentName="li">
              <li parentName="ul">{`Easy Container Shortcuts (Alex)`}</li>
              <li parentName="ul">{`Facebook Container (Alex)`}</li>
            </ul></li>
          <li parentName="ul">{`Youtube Classic (Alex)`}</li>
          <li parentName="ul">{`Plasma Integration (Alex)`}</li>
          <li parentName="ul">{`Pesticide (Seb)`}</li>
          <li parentName="ul">{`Better History (Seb)`}</li>
          <li parentName="ul">{`RES (Seb)`}</li>
          <li parentName="ul">{`Floating for YouTube (Seb)`}</li>
        </ul></li>
      <li parentName="ul">{`Smithsonian Open Access: `}<a parentName="li" {...{
          "href": "https://www.si.edu/openaccess"
        }}>{`https://www.si.edu/openaccess`}</a></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <h3>{`Alex PinePhone`}</h3>
    <ul>
      <li parentName="ul">{`Mycket resande, ont om tid`}</li>
    </ul>
    <h3>{`Sebs Linuxäventyr`}</h3>
    <ul>
      <li parentName="ul">{`Switcha är jobbigt. Kan man nå full enlightenment?`}</li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Zero-day från @gargoyleclown`}</li>
    </ul>
    <h3>{`Contributor-ligan`}</h3>
    <ul>
      <li parentName="ul">{`@hund och andra som går above and beyond`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Mystical Sting" av Alexander Nakarada`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0 (`}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a>{`)`}</li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      